import * as React from 'react';
import tw from 'twin.macro';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { models } from '../../models';
import { seoData } from '../data/seo';
import { defaultLangKey } from '../data/languages';
import ContentWidth from '../components/contentWidth';
import Title from '../components/title';
import { graphql } from 'gatsby';
import { FixedObject, FluidObject } from 'gatsby-image';
import GatsbyImage from 'gatsby-image';
import LearnMore from '../components/learnMore';
import { Link } from '../utils/link';

type ServicesNode = {
  id: string;
  top: {
    title: string;
    description: string[];
    list: {
      id: string;
      title: string[];
      image: { childImageSharp: { fixed: FixedObject } };
    }[];
  };
  school: {
    title: string[];
    description: string[];
    pricing: {
      title: string[];
      table: {
        head: string[];
        bodyColors: string[];
        body: {
          number: string;
          start: string;
          frequency: string;
          price: string;
        }[];
      };
      description: string[];
      bonus: {
        title: string[];
        list: string[];
      };
    };
  };
  essay: {
    title: string[];
    description: string[];
    plan: {
      title: string[];
      list: {
        title: string[];
        color: string;
        description: string[];
      }[];
    };
    pricing: {
      title: string[];
      table: {
        head: string[];
        bodyColors: string[];
        body: {
          name: string;
          spelling: boolean;
          structure: boolean;
          translation: boolean;
          topic: boolean;
          interview: boolean;
          price_1: string;
          price_2: string;
        }[];
      };
      description: string[];
    };
  };
  toefl: {
    title: string[];
    description: string[];
    pricing: {
      title: string[];
      price: string;
      link: string;
      description: string;
      buyDescription: string;
    };
    notice: string[];
  };
  allInOne: {
    title: string[];
    description: string[];
    services: {
      title: string;
      list: {
        title: string[];
        image: { childImageSharp: { fluid: FluidObject } };
      }[];
    };
    pricing: {
      title: string;
      price: string;
      description: string[];
    };
  };
  counseling: {
    title: string[];
    content: {
      title: string;
      description: string[];
    };
    businessHours: {
      title: string;
      description: string[];
      contact: {
        link: string;
        title: string;
        description: string[];
      };
    };
  };
};

type Props = {
  location: Location;
  data: {
    services: {
      edges: [{ node: ServicesNode }];
    };
  };
};

function Services({ location, data: { services } }: Props) {
  return (
    <Layout
      lang={defaultLangKey}
      location={location}
      pathnameWithoutLang={models.pages!.services.path}
    >
      <SEO
        featuredImage={seoData.services.featuredImage?.[defaultLangKey]}
        title={seoData.services.title[defaultLangKey]}
        description={seoData.services.description[defaultLangKey]}
        lang={defaultLangKey}
        pathname={location.pathname}
      />
      {services.edges.map(({ node }: { node: ServicesNode }) => (
        <ContentWidth>
          <section css={tw`mb-24 md:mb-32`}>
            <Title title={node.top.title} />
            {node.top.description.length ? (
              <div
                css={tw`text-center font-bold text-sm md:text-2xl leading-loose my-12 md:my-24`}
              >
                {node.top.description.map((line, index) => (
                  <p
                    key={`top-description-${index}`}
                    css={tw`inline md:inline-block`}
                  >
                    {line}
                  </p>
                ))}
              </div>
            ) : null}
            {node.top.list.length ? (
              <ul css={tw`md:flex md:justify-between my-16`}>
                {node.top.list.map((item, index) => (
                  <li
                    css={tw`relative overflow-hidden rounded-md shadow-lg md:max-w-250px md:max-h-250px mb-3 md:mb-0 md:w-1/4 pb-1/5`}
                    key={`top-list-item-${index}`}
                  >
                    <figure
                      css={tw`absolute top-0 left-0 right-0 bottom-0 w-full h-full`}
                    >
                      <GatsbyImage
                        fixed={item.image.childImageSharp.fixed}
                        css={tw`w-full`}
                        style={{
                          position: 'absolute',
                          width: '100%',
                          bottom: '0',
                        }}
                      />
                      <span
                        css={tw`absolute top-0 left-0 right-0 bottom-0 bg-gray-700 bg-opacity-50`}
                      />
                      {item.title.length ? (
                        <figcaption
                          css={tw`absolute w-full text-center text-white`}
                          style={{
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                          }}
                        >
                          {item.title.map((line, index) => (
                            <p
                              css={tw`text-white font-bold text-lg`}
                              key={`top-list-item-title-${index}`}
                            >
                              {line}
                            </p>
                          ))}
                        </figcaption>
                      ) : null}
                    </figure>
                  </li>
                ))}
              </ul>
            ) : null}
            <LearnMore />
          </section>

          <section css={tw`mb-24 md:mb-32`}>
            <Title title={node.school.title} oneLiner={true} />
            {node.school.description.length ? (
              <div
                css={tw`my-16 bg-gray-200 font-bold leading-loose p-6 md:p-12 text-base md:text-lg rounded`}
              >
                {node.school.description.map((line, index) => (
                  <p key={`school-description-${index}`}>{line}</p>
                ))}
              </div>
            ) : null}
            <article>
              <Title
                title={node.school.pricing.title}
                size={`small`}
                oneLiner={true}
                textAlign={`left`}
                icon={faSquare}
              />

              <div css={tw`flex flex-col md:flex-row mt-6`}>
                <div css={tw`flex md:flex-col w-full`}>
                  {node.school.pricing.table.head.map((item, index) => (
                    <div
                      css={tw`bg-gray-200 px-2 py-6 md:p-6 mb-1 md:last:mb-0 mr-1 font-bold text-xs md:text-base w-1/4 md:w-auto text-center`}
                      key={`school-pricing-table-head-${index}`}
                    >
                      {item}
                    </div>
                  ))}
                </div>
                {node.school.pricing.table.body.map((item, index) => (
                  <div css={tw`flex md:flex-col w-full`}>
                    {Object.entries(item).map(([key, value]) => (
                      <div
                        key={`school-pricing-table-body-${key}-${index}`}
                        css={tw`bg-gray-200 px-2 py-6 md:p-6 mb-1 md:last:mb-0 mr-1 font-bold text-xs md:text-base text-white text-center w-1/4 md:w-auto text-center`}
                        style={{
                          backgroundColor:
                            node.school.pricing.table.bodyColors[index],
                        }}
                      >
                        {value}
                      </div>
                    ))}
                  </div>
                ))}
              </div>

              {node.school.pricing.description.length ? (
                <div
                  css={tw`my-8 md:my-16 font-bold leading-loose text-xs md:text-base rounded`}
                >
                  {node.school.pricing.description.map((line, index) => (
                    <p key={`school-pricing-description-${index}`}>{line}</p>
                  ))}
                </div>
              ) : null}

              <div
                css={tw`flex items-center px-6 md:px-12 py-6 font-bold bg-red-500 rounded`}
              >
                <div css={tw`text-lg md:text-3xl mr-6 md:mr-12`}>
                  {node.school.pricing.bonus.title.map((line, index) => (
                    <p
                      css={tw`text-white`}
                      key={`school-pricing-bonus-title-${index}`}
                    >
                      {line}
                    </p>
                  ))}
                </div>

                <div css={tw`flex items-center`}>
                  <div>
                    {node.school.pricing.bonus.list.map((line, index) => (
                      <p
                        css={tw`text-white mb-3 last:mb-0 text-sm md:text-base`}
                        key={`school-pricing-bonus-list-${index}`}
                      >
                        • {line}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            </article>
          </section>

          <section css={tw`mb-24 md:mb-32`}>
            <Title title={node.essay.title} oneLiner={true} />
            {node.essay.description.length ? (
              <div
                css={tw`my-16 bg-gray-200 font-bold leading-loose p-6 md:p-12 text-base md:text-lg rounded`}
              >
                {node.essay.description.map((line, index) => (
                  <p key={`essay-description-${index}`}>{line}</p>
                ))}
              </div>
            ) : null}

            <article css={tw`my-16`}>
              <Title
                title={node.essay.plan.title}
                oneLiner={true}
                size={`small`}
                textAlign={`left`}
                icon={faSquare}
              />

              <div
                css={tw`flex flex-col mt-6 font-bold text-sm md:text-lg leading-relaxed`}
              >
                {node.essay.plan.list.map((item, index) => (
                  <div
                    css={tw`md:flex mb-3 md:mb-1 last:mb-0 w-full text-sm md:text-lg`}
                    style={{
                      backgroundColor: `rgba(${parseInt(
                        item.color.substring(1, 3),
                        16
                      )}, ${parseInt(
                        item.color.substring(3, 5),
                        16
                      )}, ${parseInt(item.color.substring(5, 7), 16)},.1)`,
                    }}
                  >
                    <div
                      key={`essay-plan-title-${index}`}
                      css={tw`text-white py-4 px-3 font-bold flex justify-center items-center w-full md:w-1/4`}
                      style={{ backgroundColor: item.color }}
                    >
                      <div css={tw`text-sm md:text-xl text-white`}>
                        {item.title}
                      </div>
                    </div>
                    <div
                      key={`essay-plan-description-${index}`}
                      css={tw`p-8 bg-opacity-50 w-full md:w-3/4 text-sm md:text-base`}
                    >
                      {item.description.map((line, index) =>
                        !!line ? (
                          <p key={`essay-plan-description-line-${index}`}>
                            {line}
                          </p>
                        ) : (
                          <br />
                        )
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </article>

            <article>
              <Title
                title={node.essay.pricing.title}
                oneLiner={true}
                size={`small`}
                textAlign={`left`}
                icon={faSquare}
              />
              <div css={tw`overflow-scroll w-full`}>
                <div
                  css={tw`table w-full md:flex mt-6 overflow-scroll text-center`}
                >
                  <div css={tw`table-row md:flex md:flex-col w-full md:w-1/5`}>
                    {node.essay.pricing.table.head.map((item, index) => (
                      <div
                        key={`essay-pricing-table-head-${index}`}
                        css={tw`table-cell md:block p-6 font-bold bg-gray-200 mb-1 last:mb-0 mr-1 whitespace-no-wrap w-1/3 md:w-auto`}
                      >
                        {item}
                      </div>
                    ))}
                  </div>
                  {node.essay.pricing.table.body.map((item, index) => (
                    <div css={tw`table-row md:flex md:flex-col w-full`}>
                      {Object.entries(item).map(([key, value]) => (
                        <div
                          key={`essay-pricing-table-body-${key}-${index}`}
                          css={tw`table-cell md:block p-6 text-white font-bold text-center mb-1 last:mb-0 mr-1 w-1/3 md:w-auto whitespace-no-wrap`}
                          style={{
                            backgroundColor:
                              node.essay.pricing.table.bodyColors[index],
                          }}
                        >
                          {typeof value === 'boolean' ? (
                            value ? (
                              '●'
                            ) : (
                              <span css={tw`invisible`}>☓</span>
                            )
                          ) : (
                            value
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <div>
                {node.essay.pricing.description.length ? (
                  <div
                    css={tw`my-10 md:my-16 font-bold leading-loose text-sm md:text-base rounded`}
                  >
                    {node.essay.pricing.description.map((line, index) => (
                      <p key={`essay-pricing-description-line-${index}`}>
                        {line}
                      </p>
                    ))}
                  </div>
                ) : null}
              </div>
            </article>
          </section>

          <section css={tw`mb-24 md:mb-32`}>
            <Title title={node.toefl.title} oneLiner={true} />
            <div
              css={tw`my-16 bg-gray-200 font-bold leading-loose p-6 md:p-12 text-base md:text-lg rounded`}
            >
              {node.toefl.description.length ? (
                <div>
                  {node.toefl.description.map((line, index) =>
                    !!line ? (
                      <p key={`toefl-description-line-${index}`}>{line}</p>
                    ) : (
                      <br />
                    )
                  )}
                </div>
              ) : null}
            </div>

            <article>
              <Title
                title={node.toefl.pricing.title}
                oneLiner={true}
                size={`small`}
                textAlign={`left`}
                icon={faSquare}
              />

              <div
                css={tw`md:flex items-center mt-6 font-bold rounded overflow-hidden`}
              >
                <div
                  css={tw`text-base md:text-2xl md:mr-3 text-white px-6 pt-6 md:py-6 md:rounded bg-red-500`}
                >
                  {node.toefl.pricing.price}
                </div>
                <div css={tw`md:flex flex-1 md:rounded bg-red-500`}>
                  <div
                    css={tw`text-white text-base md:text-2xl mr-4 p-6 md:px-12 md:py-6`}
                  >
                    {node.toefl.pricing.description}
                  </div>
                  <a
                    href={node.toefl.pricing.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    css={tw`text-white text-base md:text-xl underline hover:opacity-75 block px-6 pb-6 md:px-12 md:py-6 ml-auto`}
                  >
                    {node.toefl.pricing.buyDescription}
                  </a>
                </div>
              </div>
            </article>

            {node.toefl.notice.length ? (
              <div
                css={tw`mt-6 font-bold leading-loose text-sm md:text-base rounded`}
              >
                {node.toefl.notice.map((line, index) => (
                  <p key={`toefl-notice-line-${index}`}>{line}</p>
                ))}
              </div>
            ) : null}
          </section>

          <section css={tw`mb-24 md:mb-32`}>
            <Title title={node.allInOne.title} oneLiner={true} />

            {node.allInOne.description.length ? (
              <div
                css={tw`my-16 bg-gray-200 font-bold leading-loose p-6 md:p-12 text-base md:text-lg rounded`}
              >
                {node.allInOne.description.map((line, index) => (
                  <p key={`allInOne-description-line-${index}`}>{line}</p>
                ))}
              </div>
            ) : null}

            <article css={tw`mb-24`}>
              <Title
                title={node.allInOne.services.title}
                oneLiner={true}
                size={`small`}
                textAlign={`left`}
                icon={faSquare}
              />
              {node.allInOne.services.list.length ? (
                <ul css={tw`grid grid-cols-1 md:grid-cols-4 md:gap-4`}>
                  {node.allInOne.services.list.map((item, index) => (
                    <li
                      key={`allInOne-services-list-item-${index}`}
                      css={tw`relative rounded overflow-hidden mb-3 md:mb-0 pb-1/5 md:pb-3/4`}
                      style={{ height: 0 }}
                    >
                      <figure css={tw`absolute top-0 left-0 right-0 bottom-0`}>
                        <GatsbyImage
                          fluid={item.image.childImageSharp.fluid}
                          css={tw`h-full`}
                        />
                        <span
                          css={tw`absolute top-0 left-0 right-0 bottom-0 bg-gray-800 bg-opacity-50`}
                        />
                        {item.title.length ? (
                          <figcaption
                            css={tw`absolute w-full text-center py-2`}
                            style={{
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                            }}
                          >
                            {item.title.map((line, index) => (
                              <p
                                key={`top-list-item-title-${index}`}
                                css={tw`text-white font-bold text-base md:text-xl`}
                              >
                                {line}
                              </p>
                            ))}
                          </figcaption>
                        ) : null}
                      </figure>
                    </li>
                  ))}
                </ul>
              ) : null}
            </article>

            <article css={tw`mb-24`}>
              <Title
                title={node.allInOne.pricing.title}
                oneLiner={true}
                size={`small`}
                textAlign={`left`}
                icon={faSquare}
              />
              <div
                css={tw`bg-purple-600 text-white font-bold text-xl text-center px-3 py-10 rounded`}
              >
                {node.allInOne.pricing.price}
              </div>
              {node.allInOne.pricing.description.length ? (
                <div
                  css={tw`my-16 bg-gray-200 font-bold leading-loose p-6 md:p-12 text-base md:text-lg rounded`}
                >
                  {node.allInOne.pricing.description.map((line, index) => (
                    <p key={`allInOne-pricing-description-line-${index}`}>
                      {line}
                    </p>
                  ))}
                </div>
              ) : null}
            </article>
          </section>

          <section css={tw`mb-24 md:mb-32`}>
            <Title title={node.counseling.title} oneLiner={true} />
            <article css={tw`mb-12`}>
              <Title
                title={node.counseling.content.title}
                oneLiner={true}
                size={'small'}
                icon={faSquare}
              />
              {node.counseling.content.description.length ? (
                <div
                  css={tw`mt-6 mb-16 md:my-16 bg-gray-200 font-bold leading-loose p-6 md:p-12 text-base md:text-lg rounded`}
                >
                  {node.counseling.content.description.map((line, index) => (
                    <p key={`counseling-content-description-line-${index}`}>
                      {line}
                    </p>
                  ))}
                </div>
              ) : null}
            </article>

            <article css={tw`mb-12`}>
              <Title
                title={node.counseling.businessHours.title}
                oneLiner={true}
                size={`small`}
                textAlign={`left`}
                icon={faSquare}
              />
              {node.counseling.businessHours.description.length ? (
                <div
                  css={tw`mt-6 mb-16 md:my-16 bg-gray-200 font-bold leading-loose p-6 md:p-12 text-base md:text-lg rounded`}
                >
                  {node.counseling.businessHours.description.map(
                    (line, index) =>
                      !!line ? (
                        <p
                          key={`counseling-businessHours-description-line-${index}`}
                        >
                          {line}
                        </p>
                      ) : (
                        <br />
                      )
                  )}
                </div>
              ) : null}
              <Link
                to={models.pages.contact.path}
                css={tw`bg-orange-400 text-center px-3 py-10 rounded text-white font-bold text-xl block hover:opacity-75`}
              >
                {node.counseling.businessHours.contact.title}
              </Link>
              {node.counseling.businessHours.contact.description.length ? (
                <div
                  css={tw`mt-6 font-bold leading-loose text-sm md:text-base rounded`}
                >
                  {node.counseling.businessHours.contact.description.map(
                    (line, index) =>
                      !!line ? (
                        <p
                          key={`counseling-businessHours-contact-description-line-${index}`}
                        >
                          {line}
                        </p>
                      ) : (
                        <br />
                      )
                  )}
                </div>
              ) : null}
            </article>
          </section>
        </ContentWidth>
      ))}
    </Layout>
  );
}

export const servicesQuery = graphql`
  query ServicesQuery {
    services: allServicesJson {
      edges {
        node {
          id
          top {
            title
            description
            list {
              id
              title
              image {
                childImageSharp {
                  fixed(width: 250, height: 250) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
          school {
            title
            description
            pricing {
              title
              table {
                head
                bodyColors
                body {
                  number
                  start
                  frequency
                  price
                }
              }
              description
              bonus {
                title
                list
              }
            }
          }
          essay {
            title
            description
            plan {
              title
              list {
                color
                title
                description
              }
            }
            pricing {
              title
              table {
                head
                bodyColors
                body {
                  name
                  spelling
                  structure
                  translation
                  topic
                  interview
                  price_1
                  price_2
                }
              }
              description
            }
          }
          toefl {
            title
            description
            pricing {
              title
              price
              link
              description
              buyDescription
            }
            notice
          }
          allInOne {
            title
            description
            services {
              title
              list {
                title
                image {
                  childImageSharp {
                    fluid(maxWidth: 500) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            pricing {
              title
              price
              description
            }
          }
          counseling {
            title
            content {
              title
              description
            }
            businessHours {
              title
              description
              contact {
                link
                title
                description
              }
            }
          }
        }
      }
    }
  }
`;

export default Services;
